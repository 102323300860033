import React, { useEffect, useState } from 'react'
import { Grid, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  UnorderedListOutlined,
  GlobalOutlined
} from '@ant-design/icons'
import { get } from '../../Common/api'
import { Color } from '../../Common/Color'
import { useLocation } from 'react-router'
import BoxList from './BoxList'
import BoxMap from './BoxMap'
import BoxListHeader from './BoxListHeader'
import PublicHeader from '../PublicHeader'
const { useBreakpoint } = Grid

export default function BoxListPage () {
  const { t } = useTranslation()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [boxes, setBoxes] = useState([])
  const [currentBox, setCurrentBox] = useState()
  const params = new URLSearchParams(location.search)
  const screens = useBreakpoint()
  const [tabActive, setTabActive] = useState('list')

  const lat = params.get('lat')
  const long = params.get('long')

  useEffect(() => {
    setLoading(true)
    get('public/boxes', { lat: lat || '', long: long || '' }, (json) => {
      setBoxes(json)
      setLoading(false)
    })
  }, [])

  const neareastBoxes = boxes.filter(box => box.distance <= 50)
  const textList = neareastBoxes.length > 0 ? t('Public.BoxList.selectBox') : t('Public.BoxList.noBox')
  return (
        <div style={{ gap: '1em', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: Color.white }}>
            <PublicHeader />
            <BoxListHeader lat={lat} long={long} />
            {screens.lg &&
                <>
                    {
                        loading &&
                        <Spin spinning={loading} >
                            <div style={{ height: '300px' }} />
                        </Spin>
                    }
                    {
                        !loading && <div className='body' style={{ flex: 1, gap: '1em', display: 'flex', flexDirection: 'horizontal', position: 'relative', overflow: 'auto' }}>
                            <div style={{ overflow: 'auto' }}>
                                <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginBottom: '1em' }}>{textList}</div>
                                <BoxList currentBox={currentBox} setCurrentBox={setCurrentBox} boxes={neareastBoxes} />
                            </div>
                            <BoxMap currentBox={currentBox} setCurrentBox={setCurrentBox} boxes={boxes} lat={lat} long={long} />
                        </div>
                    }
                </>

            }
            {!screens.lg &&
                <>
                    <div style={{ backgroundColor: Color.dark_blue }}>
                        <UnorderedListOutlined style={tabActive === 'list' ? activeStyle : inactiveStyle} onClick={() => { setTabActive('list') }} />
                        <GlobalOutlined style={tabActive === 'map' ? activeStyle : inactiveStyle} onClick={() => { setTabActive('map') }} />
                    </div>
                {!loading && <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{textList}</div>}
                    {tabActive === 'list' &&
                        <Spin spinning={loading}>
                            <BoxList boxes={neareastBoxes} currentBox={currentBox} setCurrentBox={setCurrentBox} />
                        </Spin>
                    }
                    {tabActive === 'map' &&
                        <BoxMap currentBox={currentBox} setCurrentBox={setCurrentBox} boxes={boxes} lat={lat} long={long} />
                    }
                </>
            }
        </div>
  )
}

const inactiveStyle = {
  backgroundColor: Color.dark_blue,
  color: Color.white,
  fontSize: '2em',
  padding: '0.5em'
}

const activeStyle = {
  backgroundColor: Color.white,
  color: Color.dark_blue,
  fontSize: '2em',
  padding: '0.5em'
}
