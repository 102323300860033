import { Button, Layout, Popconfirm, Spin, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import SlotForm from '../BoxPage/SlotForm'
import { useNavigate, useParams } from 'react-router'
import { useForm } from 'antd/es/form/Form'
import { get, post, put } from '../../Common/api'
import dayjs from 'dayjs'
import { t } from 'i18next'
import LogsPanel from '../Logs/LogsPanel'
import _ from 'lodash'
import CreateEventCard from '../EventsPage/CreateEventCard'

export default function AdminSlotPage () {
  const { id } = useParams()
  const [form] = useForm()
  const [slot, setSlot] = useState({})
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getSlot()
    getLogs()
    setLoading(false)
  }, [])

  const getLogs = async () => {
    await get('admin/logs/slot', { slot_id: id }, (json) => {
      setLogs(_.groupBy(json, (log) => dayjs(log.created_at).format('YYYY-MM-DD')))
    })
  }

  const getSlot = async () => {
    await get(`admin/slots/${id}`, {}, (json) => {
      setSlot(json)
      json = { ...json, range: [dayjs(json.begin_date), dayjs(json.end_date)], start: dayjs(json.start) }
      form.setFieldsValue(json)
    })
  }

  const onFinish = (values) => {
    saveSlot(values)
  }

  const saveSlot = (params) => {
    params = { ...params, begin_date: params.range[0], end_date: params.range[1] }
    setLoading(true)
    put(`admin/slots/${id}`, params, () => {
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  const cancel = () => {
    setLoading(true)
    post(`admin/slots/${id}/cancel`, {}, (json) => {
      setSlot(json)
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, () => {
      setLoading(false)
      notification.error({ message: t('Admin.message.updateError'), placement: 'bottom' })
    })
  }

  const logPanels = () => {
    if (Object.keys(logs).length === 0) {
      return <span style={{ textAlign: 'center' }}>{t('Admin.Logs.empty')}</span>
    }
    return Object.keys(logs).map((key, index) => {
      return <LogsPanel logs={logs[key]} date={key} key={`log-${index}`}/>
    })
  }

  const back = () => {
    navigate(`/admin/box/${slot.box_id}/calendar`)
  }

  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white', marginTop: '2em', justifyContent: 'space-between' }}>
      <Spin spinning={loading}>
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative' }}>
            <SlotForm form={form} edit={true} onFinish={onFinish} disabled={slot.is_cancel} specialist={slot.specialist}/>
            <Button type="primary" htmlType="submit" onClick={form.submit} disabled={slot.is_cancel}>
              {t('Admin.btn.submit')}
            </Button>
            <Popconfirm
              title={t('Admin.AdminSlotPage.cancelSlot')}
              onConfirm={cancel}
              okText={t('Admin.message.yes')}
              cancelText={t('Admin.message.no')}
              disabled={slot.is_cancel}
            >
              <Button style={{ marginLeft: '1em' }} danger disabled={slot.is_cancel}>
              {t('Admin.btn.cancel')}
            </Button>
            </Popconfirm>
            <Button style={{ position: 'absolute', right: '5px' }}
            type="primary" onClick={back}>
              {t('Admin.btn.back')}
            </Button>
          </div>
          <div style={{ marginLeft: '20em', gap: '1em', display: 'flex', flexDirection: 'column', minWidth: '30em' }}>
            <h3 style={{ textAlign: 'center' }}>{t('Admin.AdminSlotPage.logHeader')}</h3>
            {logPanels()}
          </div>
          <div style={{ margin: '3.7em 0 0 6em' }}>
            <CreateEventCard box={{ id: slot.box_id }} slot={slot}/>
          </div>
        </div>
      </Spin>
    </Layout>
  )
}
