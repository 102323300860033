import { Button, Col, Form, Input, Radio, Row, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'
import PhoneInput from 'react-phone-number-input/input'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { CircleMarker, MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import { Color } from '../../Common/Color'
import { UploadOutlined } from '@ant-design/icons'

export default function BoxForm ({ form, onFinish, owners, show }) {
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState()
  const [mapVisible, setMapVisible] = useState(false)

  const onSubmit = (values) => {
    if (values.interphone) {
      values.interphone = formatPhoneNumber(values.interphone).replaceAll(' ', '')
    }
    if (values.gps_point) {
      values.gps_point = `POINT(${values.gps_point})`
    } else {
      values.gps_point = null
    }
    onFinish(values)
  }

  useEffect(() => {
    setTimeout(() => {
      setMapVisible(true)
    }, 500)
  }, [])

  useEffect(() => {
    map?.invalidateSize()
    const gpsPoint = form.getFieldValue('gps_point')
    if (gpsPoint) {
      const newCenter = gpsPoint ? gpsPoint.split(' ').reverse() : null
      setCenter(newCenter)
      map?.setView(newCenter, map.getZoom())
    }
  }, [mapVisible, map])

  const onGpsChange = (e) => {
    if (/^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g.test(e.target.value)) {
      const newCenter = e.target.value.split(' ').reverse()
      setCenter(newCenter)
    }
  }

  const LocationFinderDummy = () => {
    useMapEvents({
      click (e) {
        form.setFieldsValue({ gps_point: `${e.latlng.lng} ${e.latlng.lat}` })
        setCenter([e.latlng.lat, e.latlng.lng])
      }
    })
    return null
  }

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e[0].originFileObj
    }
    return e && e.fileList[0].originFileObj
  }

  const beforeUpload = () => {
    return false
  }

  const fileList = form.getFieldValue('picture_url')?.url
    ? [
        {
          url: form.getFieldValue('picture_url')?.url
        }
      ]
    : []

  return (
    <Form
      id="boxForm"
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onSubmit}
      disabled={show}
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item
            name="picture"
            label={t('Admin.BoxForm.picture')}
            getValueFromEvent={getFile}
            rules={[{ required: true, message: t('rules.required') }]}
          >
            <Upload name="picture"
              beforeUpload={beforeUpload}
              listType="picture"
              maxCount={1}
              defaultFileList={[...fileList]}
              showUploadList={{ showRemoveIcon: false }}
              accept='.jpg,.png'>
              <Button icon={<UploadOutlined />}>{t('Admin.BoxForm.pictureButton')}</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="owner_id" label={t('Admin.BoxForm.owner')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Select
              showSearch
              placeholder="Selectionner une collectivité"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={owners?.map((o) => ({ label: o.name, value: o.id }))}
            />
          </Form.Item>
          <Form.Item name="name" label={t('Admin.BoxForm.name')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="active">
            <Radio.Group>
          <Radio value={true}>{t('Admin.BoxForm.active')}</Radio>
          <Radio value={false}>{t('Admin.BoxForm.inactive')}</Radio>
        </Radio.Group>
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item name="city" label={t('Admin.BoxForm.city')}
                rules={[{ required: true, message: t('rules.required') }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="zip_code" label={t('Admin.BoxForm.zip_code')}
                rules={[{ required: true, message: t('rules.required') }]}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="address" label={t('Admin.BoxForm.address')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gps_point" label={t('Admin.BoxForm.gps')}
            rules={[{ required: true, pattern: /^-?[0-9]+\.?[0-9]* -?[0-9]+\.?[0-9]*$/g }]}

          >
            <Input onChange={onGpsChange} />
          </Form.Item>
          {mapVisible && <MapContainer
            ref={setMap}
            style={{ borderRadius: '2em', height: '200px', width: '100%' }} center={center || [48.856614, 2.3522219]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {center && <CircleMarker center={center} radius={8} pathOptions={{ color: Color.white, fillColor: Color.red, weight: 2, fillOpacity: 1 }}></CircleMarker>}
            <LocationFinderDummy />
          </MapContainer>}
          <Form.Item name="comment" label={t('Admin.BoxForm.comment')}>
            <TextArea rows={5} />
          </Form.Item>
          <h2>{t('Admin.BoxForm.locks')}</h2>
          <Form.Item name="lock_1" label={t('Admin.BoxForm.lock_1')}>
            <Input />
          </Form.Item>
          <Form.Item name="lock_2" label={t('Admin.BoxForm.lock_2')}>
            <Input />
          </Form.Item>
          <Form.Item name="lock_3" label={t('Admin.BoxForm.lock_3')}>
            <Input />
          </Form.Item>
          <Form.Item name="lock_4" label={t('Admin.BoxForm.lock_4')}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="calendoc_id" label={t('Admin.BoxForm.calendoc_id')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="interphone" label={t('Admin.BoxForm.interphone')}
            rules={[
              {
                validator: (_, value) =>
                  value === undefined || isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))
              }
            ]}>
            <PhoneInput country='FR' className='ant-input css-dev-only-do-not-override-xfszwz' disabled={show} />
          </Form.Item>
          <Form.Item name="pcc_phone" label={t('Admin.BoxForm.pcc_phone')}
            rules={[
              {
                validator: (_, value) =>
                  value === undefined || isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))
              }
            ]}>
            <PhoneInput country='FR' className='ant-input css-dev-only-do-not-override-xfszwz' disabled={show} />
          </Form.Item>
          <Form.Item name="video_surveillance_url" label={t('Admin.BoxForm.video_surveillance_url')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
            <a href={form.getFieldValue('video_surveillance_url')} target="_blank" rel="noreferrer">{t('Admin.BoxForm.video_surveillance_url')}</a>
        </Col>
      </Row>
    </Form>
  )
}
