import { Layout, Modal, Popover, Spin, Tag } from 'antd'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import { PhoneOutlined, TableOutlined } from '@ant-design/icons'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr'
import BoxContext from './BoxContext'
import { useForm } from 'antd/es/form/Form'
import SlotForm from './SlotForm'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import { SLOT_COLORS } from '../../Common/Color'
import { Link } from 'react-router-dom'
import AvailableNowCard from './AvailableNowCard'
import CreateEventCard from '../EventsPage/CreateEventCard'

dayjs.extend(weekday)
dayjs.extend(localeData)

const title = (slot) => {
  const name = `${slot.first_name} ${slot.last_name}`
  if (slot.slot_type === 'clean') {
    return `[Nettoyage] ${name}`
  } else if (slot.slot_type === 'maintenance') {
    return `[Maintenace] ${name}`
  } else if (slot.slot_type === 'patient') {
    return `${name}`
  }
}

const transformSlot = (slot) => {
  return {
    id: slot.id,
    start: dayjs(slot.begin_date).format(),
    end: dayjs(slot.end_date).subtract(1, 'second').format(),
    color: SLOT_COLORS[slot.slot_type],
    title: title(slot),
    className: !slot.sms_confirmed && 'slot-stripped'
  }
}

const getMinMaxHour = (box, slots) => {
  let minHour = 8
  let maxHour = 18
  if (!box || !box.open_0) { return [minHour, maxHour] }
  // boucle sur les heures d'ouverture et de fermeture de la box
  [0, 1, 2, 3, 4, 5, 6].forEach((x) => {
    if (box[`open_${x}`] !== box[`close_${x}`]) {
      if (minHour > dayjs(box[`open_${x}`]).hour()) {
        minHour = dayjs(box[`open_${x}`]).hour()
      }
      if (maxHour < dayjs(box[`close_${x}`]).hour()) {
        maxHour = dayjs(box[`close_${x}`]).hour()
      }
    }
  })
  // boucle sur les slots pour trouver les heures min et max
  slots.forEach((slot) => {
    if (minHour > dayjs(slot.begin_date).hour()) {
      minHour = dayjs(slot.begin_date).hour()
    }
    if (maxHour < dayjs(slot.end_date).hour()) {
      maxHour = dayjs(slot.end_date).hour()
    }
  })
  minHour = minHour - 2
  if (minHour < 0) { minHour = 0 }
  maxHour = maxHour + 2
  if (maxHour > 23) { maxHour = 23 }
  return [minHour, maxHour]
}

const businessHours = (box) => (
  [0, 1, 2, 3, 4, 5, 6].map((day) => (
    {
      daysOfWeek: [day],
      startTime: dayjs(box[`open_${day}`]).format('HH:mm'),
      endTime: dayjs(box[`close_${day}`]).format('HH:mm')
    }
  ))
)

const slotContent = (slot) => (
  <>
    <Tag color={t(`Admin.Slot.stateColor.${slot?.state}`)}>{t(`Admin.Slot.state.${slot?.state}`)}</Tag>
    <br />
    <PhoneOutlined /> <i>{slot?.phone}</i> <TableOutlined /> <i>{slot?.digicode}</i>
  </>
)

export default function BoxCalendarPage () {
  const { slots, loadSlots, box, saveSlot, loading } = useContext(BoxContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [rangeDisable, setRangeDisable] = useState(false)
  const [form] = useForm()

  const checkBoxAvailablity = () => {
    const now = dayjs()
    const slot = slots.find((slot) =>
      dayjs(slot.begin_date).isBefore(now) && dayjs(slot.end_date).isAfter(now)
    )
    const nextSlot = slots.find((slot) =>
      dayjs(slot.begin_date).isBefore(now.add(1, 'hour')) && dayjs(slot.end_date).isAfter(now.add(1, 'hour'))
    )
    return !(slot || nextSlot)
  }

  const getSlot = (id) => (
    slots.find((slot) => slot.id === id)
  )

  const renderEventContent = (eventInfo) => (
    <Popover content={() => slotContent(getSlot(eventInfo.event.id))} title={eventInfo.event.title}>
      <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/admin/slots/${eventInfo.event.id}`}>
        <div style={{ whiteSpace: 'nowrap' }} >
          {eventInfo.event.title}
        </div>
      </Link>
    </Popover>
  )

  const selected = (info) => {
    form.resetFields()
    form.setFieldsValue({
      slot_type: 'patient',
      range: [dayjs(info.start), dayjs(info.end)],
      is_now: false
    })
    setRangeDisable(false)

    setModalVisible(true)
  }

  const onAdd = async (values) => {
    values = { ...values, phone: values.phone.slice(1) }
    await saveSlot(values, form, () => setModalVisible(false))
  }

  const boxAvailableNow = checkBoxAvailablity()
  const [minHour, maxHour] = getMinMaxHour(box, slots)
  return (
    <>
      {box && <>
        <Modal
          open={modalVisible}
          width="800px"
          title={t('Admin.BoxCalendar.popupTitle')}
          okText={t('actions.validate')}
          confirmLoading={loading}
          onOk={form.submit}
          onCancel={() => setModalVisible(false)}
          >
          <Spin spinning={loading}>
            <SlotForm form={form} onFinish={onAdd} rangeDisable={rangeDisable} />
          </Spin>
        </Modal>
        <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
          <div style={{ display: 'flex' }}>
            {
              boxAvailableNow && <AvailableNowCard setModalVisible={setModalVisible} setRangeDisable={setRangeDisable} form={form} />
            }
            <CreateEventCard box={box}/>
          </div>
          {
            box.id && <FullCalendar
            height="600px"
            selectable={true}
            plugins={[timeGridPlugin, interactionPlugin]}
            firstDay={dayjs().day() - 1}
              initialView="timeGridWeek"
              slotDuration="01:00:00"
              locale={frLocale}
              allDaySlot={false}
              displayEventTime={false}
              businessHours={businessHours(box)}
              events={slots?.map((slot) => transformSlot(slot))}
              eventContent={renderEventContent}
              datesSet={async (params) => await loadSlots(box.id, params.start)}
              select={(info) => selected(info)}
              slotMinTime={minHour + ':00:00'}
              slotMaxTime={maxHour + ':00:00'}
            />
          }
        </Layout>
      </>
      }
    </>
  )
}
