import Layout from 'antd/es/layout/layout'
import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserHistory } from 'history'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Login from './Admin/Login'
import HomePage from './Public/HomePage/HomePage'
import ProPage from './Public/ProPage/ProPage'
import { GlobalContextProvider } from './Admin/GlobalContext'
import BoxesPage from './Admin/BoxesPage/BoxesPage'
import MenuLayout from './Admin/Menu/MenuLayout'
/* eslint-disable no-unused-vars */ // keep the following line
import i18n from './locales/i18n'
/* eslint-enable no-unused-vars */
import { ConfigProvider } from 'antd'
import frFR from 'antd/locale/fr_FR'
import BoxMenuLayout from './Admin/BoxPage/BoxMenuLayout'
import BoxCalendarPage from './Admin/BoxPage/BoxCalendarPage'
import BoxInfoPage from './Admin/BoxPage/BoxInfoPage'
import { BoxContextProvider } from './Admin/BoxPage/BoxContext'
import 'dayjs/locale/fr'
import dayjs from 'dayjs'
import i18next from 'i18next'
import BoxListPage from './Public/BoxList/BoxListPage'
import BoxPage from './Public/BoxPage/BoxPage'
import CreateSlotPage from './Public/Slot/CreateSlotPage'
import SlotPage from './Public/Slot/SlotPage'
import BoxOpeningPage from './Admin/BoxPage/BoxOpeningPage'
import SlotsPage from './Admin/SlotsPage/SlotsPage'
import AdminSlotPage from './Admin/AdminSlotPage/AdminSlotPage'
import OwnersPage from './Admin/OwnersPage/OwnersPage'
import OwnerPage from './Admin/OwnerPage/OwnerPage'
import { Color } from './Common/Color'
import AlertsPage from './Admin/AlertsPage/AlertsPage'
import UsersPage from './Admin/UsersPage/UsersPage'
import UserPage from './Admin/UserPage/UserPage'
import UpdatePassword from './Admin/UpdatePassword'
import { SpecialistContextProvider } from './Public/Specialist/SpecialistContext'
import BoxSpecialistSlotList from './Public/BoxPage/BoxSpecialistSlotList'
import CreateSpecialistSlotPage from './Public/Specialist/CreateSpecialistSlotPage'
import EventsPage from './Admin/EventsPage/EventsPage'
import ConfirmSlotForm from './Public/Slot/ConfirmSlotForm'
import { SlotContextProvider } from './Public/Slot/SlotContext'
import BoxLogsPage from './Admin/BoxPage/BoxLogsPage'
import ErrorPage from './Public/ErrorPage/ErrorPage'
import Changelog from './Admin/ChangelogPage/Changelog'

dayjs.locale('fr')
i18next.init({
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b']
  }
})
const history = createBrowserHistory()
const App = () => {
  const queryClient = new QueryClient()

  return (<Layout style={{ height: '100vh' }}>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/403" element={<ErrorPage error="403" />} />
          <Route path="/404" element={<ErrorPage error="404" />} />
          <Route path="/422" element={<ErrorPage error="422" />} />
          <Route path="/500" element={<ErrorPage error="500" />} />
          <Route path="/collectivite" element={<ProPage />} />
          <Route path="/results" element={<BoxListPage />} />
          <Route path="/box/:id" >
            <Route path="" element={<BoxPage />} />
            <Route element={<SlotContextProvider />}>
              <Route path="slot_create" element={<CreateSlotPage />} />
              <Route path="slot_create/:slotDate" element={<CreateSlotPage />} />
              <Route path="confirm_slot/:id" element={<ConfirmSlotForm />}/>
              <Route element={<SpecialistContextProvider />}>
                <Route path="specialist_slot_create" element={<CreateSpecialistSlotPage />} />
                <Route path="specialist" element={<BoxSpecialistSlotList />}/>
              </Route>
            </Route>
          </Route>
          <Route path="/slots/:id" element={<SlotPage />}/>
          <Route path="/admin" >
            <Route element={<GlobalContextProvider />}>
              <Route path="" element={<Navigate to="/admin/events" />} />
              <Route path="login" element={<Login />} />
              <Route path="password" element={<UpdatePassword />} />
              <Route element={<MenuLayout />}>
                <Route path="changelog" element={<Changelog />} />
                <Route path="boxes" element={<BoxesPage />} />
                <Route element={<BoxContextProvider />}>
                  <Route path="box/:id" element={<BoxMenuLayout />}>
                    <Route path="calendar" element={<BoxCalendarPage />} />
                    <Route path="info" element={<BoxInfoPage />} />
                    <Route path="opening" element={<BoxOpeningPage />} />
                    <Route path="logs" element={<BoxLogsPage />} />
                  </Route>
                </Route>
                <Route path="events" element={<EventsPage />} />
                <Route path="events/:id" element={<EventsPage />} />
                <Route path="slots" element={<SlotsPage />} />
                <Route path="slots/:id" element={<AdminSlotPage />} />
                <Route path="owners" element={<OwnersPage />} />
                <Route path="owners/:id" element={<OwnerPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="users/:id" element={<UserPage />} />
                <Route path="alerts" element={<AlertsPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </QueryClientProvider>
    </Router>
  </Layout>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.getElementById('root')
  const root = ReactDOM.createRoot(rootEl)
  root.render(
    <React.StrictMode>
      <ConfigProvider
        locale={frFR}
        theme={{
          token: {
            fontFamily: 'custom-font',
            colorPrimary: Color.primary,
            borderRadius: '30',
            colorInfo: '#FF4438'
          }
        }}
      >
        <App />
      </ConfigProvider>
    </React.StrictMode>
  )
})
