import { Button, Form, Layout, Spin, TimePicker } from 'antd'
import { t } from 'i18next'
import React, { useContext, useEffect } from 'react'
import BoxContext from './BoxContext'
import dayjs from 'dayjs'
import GlobalContext from '../GlobalContext'

export default function BoxOpeningPage () {
  const [form] = Form.useForm()
  const { box, updateBox, loading } = useContext(BoxContext)
  const { data } = useContext(GlobalContext)

  const canEdit = data.user.roles.includes('admin') || data.user.roles.includes('setup')

  const onFinish = (values) => {
    const params = {};
    [...Array(7)].forEach((_, i) => {
      params[`open_${i}`] = values[`day_${i}`][0]
      params[`close_${i}`] = values[`day_${i}`][1]
    })
    updateBox(params, form)
  }

  useEffect(() => {
    if (box) {
      const fields = {};
      [...Array(7)].forEach((_, i) => {
        fields[`day_${i}`] = [dayjs(box[`open_${i}`]), dayjs(box[`close_${i}`])]
      })
      form.setFieldsValue(fields)
    }
  }, [box])

  const format = 'HH:mm'

  const formOpening = [...Array(7)].map((_, i) => (
    <Form.Item
      key={i}
      name={`day_${(i + 1) % 7}`}
      label={t(`Admin.BoxOpeningPage.day_${(i + 1) % 7}`)}
      rules={[{ required: true }]}
      labelCol={{ style: { width: '100px' } }}
    >
      <TimePicker.RangePicker format={format} style={{ width: '150px' }} disabled={!canEdit}/>
    </Form.Item>
  ))

  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white' }}>
      <h1>{t('Admin.BoxOpeningPage.title')}</h1>
      <Spin spinning={loading}>
        <Form
          id="openingForm"
          form={form}
          labelAlign='left'
          onFinish={onFinish}
        >
          {formOpening}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('Admin.btn.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Layout>
  )
}
